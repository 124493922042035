<template>
    <n-space vertical style="min-height: 600px;" justify="center">
        <n-grid cols="6" item-responsive>
            <n-gi span="6">
                <n-card class="admin-card" :bordered="false" style="min-width: 1200px;">
                    <div>
                        <n-space justify="end" style="margin-bottom: 0.25rem;">
                            <n-input placeholder="search" @input="handleSearchInput">
                                <template #prefix>
                                    <n-icon>
                                        <Search28Filled />
                                    </n-icon>
                                </template>
                            </n-input>
                        </n-space>
                        <n-list hoverable bordered>
                            <n-list-item style="background-color: var(--wheat-background-color);">
                                <n-grid cols="11" style="text-align: left" x-gap="5" class="header">
                                    <n-gi span="2">
                                        Customer
                                    </n-gi>
                                    <n-gi span="1">
                                        Files
                                    </n-gi>
                                    <n-gi span="3">
                                        Dates
                                    </n-gi>
                                    <n-gi span="3">
                                        Template
                                    </n-gi>
                                    <n-gi span="2">
                                        Action
                                    </n-gi>
                                </n-grid>
                            </n-list-item>
                        </n-list>
                    </div>
                    <div style="max-height: 50vh; overflow-y: scroll">


                        <n-list hoverable bordered>
                            <n-list-item v-for="application in applications">
                                <n-grid cols="11" style="text-align: left" x-gap="5">
                                    <n-gi span="2">
                                        <n-text strong class="name-column">{{ application.last_name }},
                                            {{
                                            application.first_name
                                            }}</n-text>
                                    </n-gi>
                                    <n-gi span="1">
                                        <n-space vertical style="text-align: left">
                                            <n-a style="text-decoration: underline"
                                                @click="fetchFile(application.uuid, 'front')">ID Front</n-a>
                                            <n-a style="text-decoration: underline"
                                                @click="fetchFile(application.uuid, 'back')">ID Back</n-a>
                                            <!-- <n-a style="text-decoration: underline"
                                            @click="fetchFile(application.uuid, 'application_file')">Application
                                            File</n-a> -->
                                        </n-space>
                                    </n-gi>
                                    <n-gi span="3">
                                        <n-space vertical>
                                            <n-text strong>Appointment on: <n-text depth="3" strong type="info">{{
                                                    application.meta["appointment_date"] }}</n-text></n-text>
                                            <n-text strong>Submitted on: <n-text depth="3" strong>{{
                                                    dayjs(application.created_on).format('lll')}}</n-text></n-text>
                                        </n-space>

                                    </n-gi>
                                    <n-gi span="3">
                                        <n-grid cols="6" x-gap="10">
                                            <n-gi span="3">
                                                <n-select :options="templateOptions" @click.stop=""
                                                    @update:value="(value) => chooseTemplate(value, application.uuid)"
                                                    placeholder="Choose an office">
                                                </n-select>
                                            </n-gi>
                                            <n-gi span="3">
                                                <n-date-picker v-model:value="application.appointmentDate"
                                                    placeholder="Appointment date" type="date" @click.stop="" />
                                            </n-gi>
                                        </n-grid>
                                    </n-gi>
                                    <n-gi span="2">
                                        <n-space style="width: 100%">
                                            <n-button type="warning" style="width: 100%"
                                                v-if="!showEditView || (currentApplication !== application.uuid)"
                                                @click.stop="toggleShowEditView(application.uuid)">
                                                <template #icon>
                                                    <n-icon>
                                                        <Edit24Filled />
                                                    </n-icon>
                                                </template>
                                                Edit
                                            </n-button>
                                            <n-button type="primary" style="width: 100%"
                                                v-if="showEditView && currentApplication === application.uuid"
                                                @click.stop="toggleShowEditView(application.uuid)">
                                                <template #icon>
                                                    <n-icon>
                                                        <CancelRound />
                                                    </n-icon>
                                                </template>
                                                Cancel
                                            </n-button>
                                            <n-button type="primary" style="width: 100%"
                                                :disabled="currentApplication !== application.uuid || !templateChosen || !application.appointmentDate"
                                                @click.stop="fetchPDF(application.appointmentDate)">PDF</n-button>
                                        </n-space>

                                    </n-gi>
                                </n-grid>
                                <EditCustomer v-if="showEditView && currentApplication==application.uuid"
                                    :key="'edit-'+currentApplication" :application="application"
                                    :get-applications="getApplications" />
                            </n-list-item>
                        </n-list>
                    </div>
                    <n-divider></n-divider>
                    <n-space justify="center" :size="[5, 10]">
                        <n-button secondary type="primary">Prev</n-button>
                        <n-button secondary type="primary">Next</n-button>
                    </n-space>
                </n-card>
            </n-gi>
        </n-grid>
    </n-space>
    <n-drawer v-model:show="showPhotoIDArea" :width="800" placement="right">
        <n-drawer-content title="Stoner">
            Stoner is a 1965 novel by the American writer John Williams.
            <!-- <VuePdfEmbed annotation-layer text-layer :source="pdfSource" v-if="pdfSource" /> -->
            <n-image :src="currentImage" v-if="currentImage"></n-image>
        </n-drawer-content>
    </n-drawer>
</template>
<script setup lang="js">
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

import { Edit24Filled, Search28Filled } from "@vicons/fluent";
import { CancelRound } from "@vicons/material";
import dayjs from "dayjs";
import AirDatepicker from "air-datepicker";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ApplicationService from "@/services/application.service.js";
import EditCustomer from "../components/admin/EditCustomer.vue";
import { fileTypeMap, fileTypeVerboseMap } from "@/utils";

const store = useStore();

const showPhotoIDArea = ref(false);
const currentImage = ref();
const pdfSource = ref();
const templateChosen = ref();
const currentApplication = ref();
const appointmentDate = ref();
const showEditView = ref(false);
let debounceTimer;

const templateOptions = [
    { label: "Dublin", value: "dublin" },
    { label: "Fremont", value: "fremont" },
    { label: "Hayward", value: "hayward" },
    { label: "Modesto", value: "modesto" },
    { label: "Newark", value: "newark" },
    { label: "Redwood City", value: "redwood" },
    { label: "Santa Clara", value: "santaclara" },
    { label: "Stockton", value: "stockton" },
    { label: "Tracy", value: "tracy" },
]

const actionOptions = [
    {
        label: 'Finish Application',
        value: 'finish'
    },
    {
        label: 'Send For Print',
        value: 'send_for_print'
    },
    {
        label: 'Mark Verified',
        value: 'mark_verified'
    },
]

const applications = ref([]);

const getApplications = async () => {
    const response = await ApplicationService.getApplications();
    if (response && response.length > 0) {
        applications.value = response
    }
}
const cinit = async () => {
    getApplications()
}

onMounted(() => {
    dayjs.extend(localizedFormat);
    cinit()
})

const chooseTemplate = (value, applicationUUID) => {
    currentApplication.value = applicationUUID;
    templateChosen.value = value
}

const toggleShowEditView = (applicationUUID) => {
    showEditView.value = !showEditView.value;
    if (showEditView.value) {
        currentApplication.value = applicationUUID;
    } else {
        currentApplication.value = null;
    }
}

const triggerSearch = (value) => {
    value = value.toLowerCase()
    applications.value = applications.value.filter((item) => {
        return item.first_name.toLowerCase().includes(value) || item.last_name.toLowerCase().includes(value) || item.middle_name.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.phone.includes(value)
    })
}

const handleSearchInput = (value) => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
        if (value.length >= 3) {
            triggerSearch(value);
        } else {
            getApplications()
        }
    }, 300);
}

const fetchFile = async (uuid, fileType) => {
    showPhotoIDArea.value = !showPhotoIDArea.value;
    if (fileTypeMap[fileType] !== 3) {
        const response = await ApplicationService.fetchFile(uuid, fileTypeMap[fileType]);
        if (response.url && fileTypeMap[fileType] !== 3) {
            currentImage.value = response.url;
        }
    }

    // else if (response.url && fileTypeMap[fileType] === 3) {
    //     pdfSource.value = response.url;

    //     // Create a temporary anchor element
    //     const anchor = document.createElement('a');
    //     anchor.href = pdfSource.value;

    //     // Set the download attribute to specify the file name
    //     anchor.download = `${uuid}-ApplicationFile.pdf` || 'download';

    //     // Append the anchor to the document body
    //     document.body.appendChild(anchor);

    //     // Programmatically trigger a click event
    //     anchor.click();

    //     // Remove the anchor from the document
    //     document.body.removeChild(anchor);
    // }
}

const fetchPDF = async (appointmentDate) => {
    store.dispatch("updateIsLoading", true);
    appointmentDate = dayjs(appointmentDate).format("MM/DD/YYYY");
    for (const application of applications.value) {
        if (application.uuid === currentApplication.value) {
            application.appointmentDate = null;
        }
    }
    const response = await ApplicationService.fetchApplication(currentApplication.value, templateChosen.value, appointmentDate)
    store.dispatch("updateIsLoading", false);

    if (response.url) {
        pdfSource.value = response.url;

        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = pdfSource.value;

        // Set the download attribute to specify the file name
        anchor.download = `${currentApplication.value}-ApplicationFile.pdf` || 'download';

        // Append the anchor to the document body
        document.body.appendChild(anchor);

        // Programmatically trigger a click event
        anchor.click();

        // Remove the anchor from the document
        document.body.removeChild(anchor);
    }
}
</script>

<style scoped>
.admin-card .n-button {
    width: 75px;
    text-align: center;
}
.name-column {
    font-size: 600;
    color: #2d5383
}
</style>

