<template>
    <n-card :bordered="false" class="main-card">
        <n-h6 style="text-align: left">Edit</n-h6>
        <n-space style="width:100%; padding-top: 0rem;">
            <n-form-item label="Last Name">
                <n-input v-model:value="editForm.lastName"></n-input>
            </n-form-item>
            <n-form-item label="First Name">
                <n-input v-model:value="editForm.firstName"></n-input>
            </n-form-item>
            <n-form-item label="Middle Name">
                <n-input v-model:value="editForm.middleName"></n-input>
            </n-form-item>
            <n-form-item label="A Number">
                <n-input v-model:value="editForm.aNumber" :show-button="false" placeholder="Number" class="block-case"
                    @update:value="handleANumberChange" maxlength="9">
                    <template #prefix>A-</template>
                </n-input>
            </n-form-item>
            <n-form-item>
                <n-button secondary type="success" @click="saveApplicantDetails" :loading="isLoading">Save</n-button>
            </n-form-item>
        </n-space>
    </n-card>
</template>

<script setup lang="js">
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

import { Edit24Filled } from "@vicons/fluent";
import { ArrowDropDownCircleSharp } from "@vicons/material";
import dayjs from "dayjs";
import AirDatepicker from "air-datepicker";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ApplicationService from "@/services/application.service.js";
import { fileTypeMap, fileTypeVerboseMap } from "@/utils";

const store = useStore();
const props = defineProps([
    "application",
    "getApplications"
])

const isLoading = ref(false);
const editForm = ref({
    firstName: null,
    middleName: null,
    lastName: null,
    aNumber: null
})

onMounted(() => {
    if (props.application) {
        editForm.value = {
            firstName: props.application.first_name,
            middleName: props.application.middle_name,
            lastName: props.application.last_name,
            aNumber: props.application.a_number
        }
    }
})

const handleANumberChange = (value) => {
    const sanitizedValue = value ? value.replace(/\D+/g, '') : '';
    editForm.value.aNumber = sanitizedValue;
}

const saveApplicantDetails = async () => {
    isLoading.value = true;
    const response = await ApplicationService.updateApplicantDetails(props.application.uuid, {
        first_name: editForm.value.firstName,
        middle_name: editForm.value.middleName,
        last_name: editForm.value.lastName,
        a_number: editForm.value.aNumber
    })
    isLoading.value = false;
    if (response.uuid) {
        props.getApplications();
        store.dispatch("updateAppMessage", { msg: "Client data updated.", type: "success" })
    }
}

</script>


<style lang="css" scoped>
.main-card {
    border: 1px solid #ebebeb;
    margin-top: 1rem;
    width: 100%;
}
</style>